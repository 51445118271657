// vue
import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';

// primevue
import PrimeVue from 'primevue/config';
import 'primeicons/primeicons.css';
import 'primevue/resources/primevue.min.css';
import 'primevue/resources/themes/md-light-indigo/theme.css';

// primeflex
import 'primeflex/primeflex.css';

createApp(App)
    .use(store)
    .use(router)
    .use(PrimeVue, { ripple: true })
    .mount('#app');
