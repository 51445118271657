<template>
    <div
        class="
            flex
            justify-between
            items-center
            bg-gray-800
            px-4
            py-2
            p-shadow-4
        "
    >
        <div class="flex items-center">
            <img
                class="w-10 h-10 mr-1"
                alt="logo"
                src="../assets/clere-target-light.svg"
            />
            <div class="inline-flex text-2xl">
                <span class="text-primary">clere</span>
                <span class="text-primary font-light">golf</span>
                <span class="font-light text-white">hub</span>
            </div>
        </div>
        <div class="text-white text-opacity-60 font-light text-sm ml-4">
            Ryder Cup Utilities
        </div>
    </div>
</template>
