import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/coordinate-converter',
        name: 'CoordinateConverter',
        component: () =>
            import(
                /* webpackChunkName: "core" */ '@/views/CoordinateConverter.vue'
            ),
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/coordinate-converter',
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
