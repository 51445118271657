
// vue
import { defineComponent } from 'vue';

// components
import AppBar from '@/components/AppBar.vue';

export default defineComponent({
    components: {
        AppBar,
    },
    setup() {
        return {};
    },
});
